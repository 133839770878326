
import { ref, defineComponent, onMounted, computed } from "vue";
import { Actions } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Skeletons from "./components/Skeletons.vue";
import SinContenido from "./components/SinContenido.vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import moment from "moment";
interface ReservationDetails {
  reservaId: string;
  segmento: string;
  marca: string;
  modelo: string;
}

export default defineComponent({
  name: "dashboard",
  components: {Skeletons, SinContenido},
  setup() {
    const router = useRouter();
    const store = useStore();
    const flotaActual = ref<ReservationDetails>();
    const visibleBottom = ref(false);

    onMounted(() => {
      setCurrentPageTitle("Dashboard");
      obtenerReservasCerradas();
    });
    const currentUser = computed(() => {
      return store.getters.currentUser;
    });
    const reservas = computed(() => {
      return store.getters.allReservas;
    });
    const loading = ref(true);
    const obtenerReservasCerradas = () => {
      store
        .dispatch(Actions.GET_RESERVAS_CERRADAS, currentUser.value.driverId)
        .then(() => {
          loading.value = false;
        })
        .catch(() => {
          const [error] = Object.keys(store.getters.getCuentaErrors);
        });
    };
    const formatDate = (value) => {
      return moment(value).format("MMM D YYYY");
    };
    const formatHour = (value) => {
      return moment(value).format("LT");
    };
    const formatCurrency = (value) => {
      return value.toLocaleString("es-CL", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        style: "currency",
        currency: "CLP",
      });
    };
    const imagen = (flota) => {
      return `/media/flota/${flota.segmentoVehiculo}/${
        flota.marcaVehiculo
      }/${flota.modeloVehiculo.replace("/", "-")}.png`;
    };

    const imageUrlAlt = (event) => {
      event.target.src = "/media/mock/auto3.png";
    };

    const targetReserva = (reserva) => {
      return `#${reserva.reservaId}`;
    };

    const bgBadge = (reserva) => {
      return reserva.codigoEstado > 0 ? "bg-success" : "bg-info";
    };
    const verFlota = (flota) => {
      visibleBottom.value = true;
      flotaActual.value = flota;
    };

    return {
      loading,
      currentUser,
      reservas,
      formatDate,
      formatCurrency,
      formatHour,
      imagen,
      imageUrlAlt,
      targetReserva,
      bgBadge,
      visibleBottom,
      flotaActual,
      verFlota,
      moment,
    };
  },
});
